import { render, staticRenderFns } from "./Breeding.vue?vue&type=template&id=8227e934&scoped=true&"
import script from "./Breeding.vue?vue&type=script&lang=js&"
export * from "./Breeding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8227e934",
  null
  
)

export default component.exports